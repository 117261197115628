<template>
    <div class="flex flex-col h-screen">
        <NavbarComponent />
        <main class="block relative mt-20">
            <section class="mt-3 bg-white">
                <div class="flex flex-col bg-theme-sidebar">
                    <div class="grid grid-cols-1 md:grid-cols-4 gap-10 p-3">
                        <div>
                            <img :src="blogInfo.image" alt="">
                        </div>
                        <div class="col-span-3">
                            <h1 class="text-4xl text-white mt-5">{{ blogInfo[title] }}</h1>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col mt-4 px-3">
                    <div v-if="blogInfo[preamble]" >
                        <div v-html="blogInfo[text]"></div>
                        <div class="flex">
                            <p v-if="blogInfo.category" class="text-theme-teal bg-gray-800 rounded-md c-p-1">{{ blogInfo.category[category] }}</p>
                        </div>
                    </div>
                </div>
            </section>

        </main>
        <FooterComponent />
    </div>
</template>

<script>
    import NavbarComponent from './components/Navbar.vue'
    import FooterComponent from './components/Footer.vue'
    import FaqRenderVue from './custom/AccordionFaq.vue'
    import useAppConfig from '@core/app-config/useAppConfig';
    export default {
        name : 'BlogDetailsPage',
        components : { NavbarComponent, FooterComponent, FaqRenderVue },
        data () {
            return {
                config: useAppConfig(),
                blogInfo : {}
            }
        },
        computed :{
            getLang() {
                return this.config.lang.value;
            },
            category() {
                if (this.getLang == '') {
                    return this.swedishLang ? 'swe_category' : 'eng_category';
                } else return this.getLang == 'sv' ? 'swe_category' : 'eng_category';
            },
            title() {
                if (this.getLang == '') {
                    return this.swedishLang ? 'swe_title' : 'eng_title';
                } else return this.getLang == 'sv' ? 'swe_title' : 'eng_title';
            },
            preamble() {
                if (this.getLang == '') {
                    return this.swedishLang ? 'swe_preamble' : 'eng_preamble';
                } else return this.getLang == 'sv' ? 'swe_preamble' : 'eng_preamble';
            },
            text() {
                if (this.getLang == '') {
                    return this.swedishLang ? 'swe_text' : 'eng_text';
                } else return this.getLang == 'sv' ? 'swe_text' : 'eng_text';
            },
        },
        mounted () {
            const { slug } = this.$route.params
            if (slug) {
                this.getSingleBlog(slug)
            }
        },
        methods : {
            getSingleBlog (slug) {
                this.$useJwt.customBlog({ URL : `/${slug}/`, method : 'get'}, { params : this.queryParams })
                .then(res => {
                    this.blogInfo = res.data
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .post-gradient {
        font-style: normal;
        font-weight: 794;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        background: linear-gradient(266.01deg, #0B0875 12.33%, #00D090 113.67%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
</style>